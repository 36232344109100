import React, { Suspense } from 'react'
import { Container, CssBaseline, Link, ThemeProvider, Typography } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import InfoHome from './components/InfoHome'
import IndustryHome from './components/IndustryHome'
import NavBar from './components/NavBar'
import AppsHome from './components/AppsHome'
import OreImport from './components/OreImport'
import OreValue from './components/OreValue'
import { getIdentity } from './data'
import Grid from '@material-ui/core/Grid'
import PiProcess from './components/PiProcess'
import ReactionsProcess from './components/ReactionsProcess'
import HullPrices from './components/HullPrices'
import MarketOpportunities from './components/MarketOpportunities'
import { RecoilRoot, useRecoilValue } from 'recoil'
import Planets from './components/Planets'
import Admin from './components/Admin'
import About from './components/About'
import ESI from './components/ESI'
import Moons from './components/Moons'
import { ReactTableDefaults } from 'react-table-6'
import './react-table.css'
import Fracks from './components/Fracks'
import Reprocessing from './components/Reprocessing'
import MarketWatch from './components/MarketWatch'
import LpStore from './components/LpStore'

const darkTheme = createTheme({
    palette: {
        type: 'dark',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    width: '0.8em',
                },
                '*::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,1,0,.1)',
                    outline: '1px solid slategrey',
                },
            },
        },
    },
})

Object.assign(ReactTableDefaults, {
    minRows: 0,
    loading: false,
    defaultPageSize: 20,
    showPagination: true,
    showPageJump: false,
    showPageSizeOptions: false,
    filterable: true,
    className: '-striped -highlight',
    defaultFilterMethod: (filter, row, _) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
    },
})

function NoMatch() {
    const location = useLocation()
    return (
        <div>
            <h3>
                404 :( No match for <code>{location.pathname}</code>
            </h3>
        </div>
    )
}

function LoginButton() {
    const location = useLocation()
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Link href={`${process.env.REACT_APP_BASE_URL}sso/login?page=${location.pathname}`} color={'inherit'}>
                <Typography variant={'h4'}>
                    <img src={'/bee.png'} height={'50'} alt={'GICE'} />
                    &nbsp;Login&nbsp;
                    <img src={'/bee.png'} height={'50'} alt={'GICE'} />
                </Typography>
            </Link>
            {location.pathname === '/unauthorized.html' ? 'Access denied' : null}
        </Grid>
    )
}

function LoggedApp() {
    const loginData = useRecoilValue(getIdentity)
    const urls = loginData.apps?.map((x) => x.link)

    return (
        <>
            <NavBar />
            <Container style={{ marginTop: 50 }} maxWidth={false}>
                <Routes>
                    <Route path={'/'} element={<AppsHome />} />
                    <Route path={'/index.html'} element={<AppsHome />} />
                    <Route exact path={'/about.html'} element={<About />} />
                    {/*{urls.includes('/apps/esi.html') ? <Route path={'/apps/esi.html'} element={<ESI />} /> : null}*/}
                    {urls.includes('/apps/info.html') ? (
                        <Route exact path={'/apps/info.html'} element={<InfoHome />} />
                    ) : null}
                    {urls.includes('/apps/industry.html') ? (
                        <Route exact path={'/apps/industry.html'} element={<IndustryHome />} />
                    ) : null}
                    {urls.includes('/apps/import_ore.html') ? (
                        <Route exact path={'/apps/import_ore.html'} element={<OreImport />} />
                    ) : null}
                    {urls.includes('/apps/appraise_ore.html') ? (
                        <Route exact path={'/apps/appraise_ore.html'} element={<OreValue />} />
                    ) : null}
                    {urls.includes('/apps/process_pi.html') ? (
                        <Route exact path={'/apps/process_pi.html'} element={<PiProcess />} />
                    ) : null}
                    {urls.includes('/apps/process_reactions.html') ? (
                        <Route exact path={'/apps/process_reactions.html'} element={<ReactionsProcess />} />
                    ) : null}
                    {urls.includes('/apps/market_watch.html') ? (
                        <Route exact path={'/apps/market_watch.html'} element={<MarketWatch />} />
                    ) : null}
                    {urls.includes('/apps/hull_prices.html') ? (
                        <Route
                            exact
                            path={'/apps/hull_prices.html'}
                            element={<HullPrices build_type={'hull_prices'} />}
                        />
                    ) : null}
                    {urls.includes('/apps/modules_prices.html') ? (
                        <Route
                            exact
                            path={'/apps/modules_prices.html'}
                            element={<HullPrices build_type={'modules_prices'} />}
                        />
                    ) : null}
                    {urls.includes('/apps/market_opportunities.html') ? (
                        <Route exact path={'/apps/market_opportunities.html'} element={<MarketOpportunities />} />
                    ) : null}
                    {/*{urls.includes('/apps/delve_planets.html') ? (*/}
                    {/*    <Route exact path={'/apps/delve_planets.html'} element={<Planets />} />*/}
                    {/*) : null}*/}
                    {/*{urls.includes('/apps/moons.html') ? (*/}
                    {/*    <Route exact path={'/apps/moons.html'} element={<Moons />} />*/}
                    {/*) : null}*/}
                    {/*{urls.includes('/apps/kf_fracks.html') ? (*/}
                    {/*    <Route exact path={'/apps/kf_fracks.html'} element={<Fracks corp_id={98370861} />} />*/}
                    {/*) : null}*/}
                    {/*{urls.includes('/apps/or1_fracks.html') ? (*/}
                    {/*    <Route exact path={'/apps/or1_fracks.html'} element={<Fracks corp_id={98628849} />} />*/}
                    {/*) : null}*/}
                    {/*{urls.includes('/apps/pidyn_fracks.html') ? (*/}
                    {/*    <Route exact path={'/apps/pidyn_fracks.html'} element={<Fracks corp_id={98724468} />} />*/}
                    {/*) : null}*/}
                    {/*{urls.includes('/apps/dbi_fracks.html') ? (*/}
                    {/*    <Route exact path={'/apps/dbi_fracks.html'} element={<Fracks corp_id={1627077595} />} />*/}
                    {/*) : null}*/}
                    {urls.includes('/apps/reprocessing.html') ? (
                        <Route exact path={'/apps/reprocessing.html'} element={<Reprocessing />} />
                    ) : null}
                    {urls.includes('/apps/lp_store.html') ? (
                        <Route exact path={'/apps/lp_store.html'} element={<LpStore />} />
                    ) : null}
                    {urls.includes('/apps/admin.html') ? (
                        <Route exact path={'/apps/admin.html'} element={<Admin />} />
                    ) : null}
                    <Route path={'*'} element={<NoMatch />} />
                </Routes>
            </Container>
        </>
    )
}

function MyApp() {
    const loginData = useRecoilValue(getIdentity)
    if (loginData === null) {
        window.location.href = '/sso/login';
        return null
    }
    else return <LoggedApp/>
    //return <>{loginData === null ? <LoginButton /> : <LoggedApp />}</>
}

function App() {
    return (
        <RecoilRoot>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <Router>
                    <HelmetProvider>
                        <Helmet titleTemplate="%s | App">
                            <html lang="en" />
                            <meta charSet="utf-8" />
                            <title>Home</title>
                        </Helmet>
                        <Suspense fallback={<div />}>
                            <MyApp />
                        </Suspense>
                    </HelmetProvider>
                </Router>
            </ThemeProvider>
        </RecoilRoot>
    )
}

export default App
